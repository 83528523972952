import axios from "axios";

export const GATSBY_APP_BACKEND_URI = process.env.GATSBY_APP_BACKEND_URI;

axios.defaults.baseURL = GATSBY_APP_BACKEND_URI;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.timeout = 2000;

export type ResignReason = "spam" | "too_often" | "not_interested" | "wont_say";

export async function verifySubscription(subscriptionId: string) {
  const { data } = await axios.post(`/subscriptions/${subscriptionId}/verify`)

  return data
}

export async function createSubscription(email: string, first_name: string, marketing: boolean, tags?: {[k: string]: string}) {
  await axios.post(`/subscriptions`, JSON.stringify({
    subscription: {
      email,
      first_name,
      marketing,
      tags
    }
  }))
}

export async function cancelSubscription(subscriptionId: string, resign_reason: ResignReason) {
  await axios.delete(
    `/subscriptions/${subscriptionId}`,
    {
      params:
        {
          resign_reason
        }
    }
  )
}

export async function getSubscription(subscriptionId: string) {
  return await axios.get(`/subscriptions/${subscriptionId}`)
}
