import * as React from "react";
import {graphql, PageProps} from "gatsby";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InfoIcon from '@mui/icons-material/Info';
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import {createSubscription} from "services/subscription";
import {NEWSLETTER_HASHES} from "config";

interface Tag {
  label: string;
  name: string;
  options?: {
    label: string;
    value: string
  }[]
}

const HASHES_CONFIG: { [hash: string]: {description: string; tags: Tag[]} } = {
  [NEWSLETTER_HASHES.newUnit]: {
    description: "Podaj swoje dane i zapisz się do naszego newslettera. Poinformujemy Cię, gdy otworzymy placówkę w Twoim województwie.",
    tags: [
      {
        label: "Województwo",
        name: "voivodeship",
        options: [
          {
            label: "Kujawsko-Pomorskie",
            value: "kujawsko-pomorskie"
          },
          {
            label: "Lubelskie",
            value: "lubelskie"
          },
          {
            label: "Lubuskie",
            value: "lubuskie"
          },
          {
            label: "Małopolskie",
            value: "malopolskie"
          },
          {
            label: "Opolskie",
            value: "opolskie"
          },
          {
            label: "Podkarpackie",
            value: "podkarpackie"
          },
          {
            label: "Podlaskie",
            value: "podlaskie"
          },
          {
            label: "Pomorskie",
            value: "pomorskie"
          },
          {
            label: "Śląskie",
            value: "slaskie"
          },
          {
            label: "Świętokrzyskie",
            value: "swietokrzyskie"
          },
          {
            label: "Warmińsko-Mazurskie",
            value: "warminsko-mazurskie"
          },
          {
            label: "Wielkopolskie",
            value: "wielkopolskie"
          },
          {
            label: "Zachodniopomorskie",
            value: "zachodniopomorskie"
          }
        ]
      }
    ]
  }
}

export default function NewsletterPage({location}: PageProps) {
  const hash = location.hash ? location.hash.replace("#", "") : ""
  const tagsToFill = HASHES_CONFIG[hash]?.tags

  const [infoVisible, setInfoVisible] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [marketing, setMarketing] = React.useState(false);
  const [tags, setTags] = React.useState(tagsToFill?.reduce((prevTag, currTag) => ({...prevTag, [ currTag.name ] : "" }), {} as {[tag: string]: string}));
  const [isSubmitted, setSubmitted] = React.useState(false);

  const isValid = email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) && !!name.length && marketing;

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    await createSubscription(email, name, marketing);
    setSubmitted(true);
  };

  return (
    <SiteLayout>
      <Section padding={2}>
        <Grid container spacing={[2, 10]} justifyContent="center" my={[2, 10]}>
          <Grid item xs={12}>
            <Typography variant="h1" color="primary" textAlign="center" mb={2}>
              Zostańmy w kontakcie!
            </Typography>
            <Typography variant="h2" textAlign="center">
              {
                HASHES_CONFIG[hash]?.description ||
                "Zapisz się do naszego newslettera. Znajdziesz tam materiały, które pomogą Tobie i Twoim bliskim w dbaniu o swoje zdrowie - prawdziwa edukacja medyczna w praktyce."
              }
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {
              isSubmitted ? (
                <Typography textAlign="center" color="primary">
                  Dziękujemy! Sprawdź swoją skrzynkę email i potwierdź zapisanie się do newslettera.
                </Typography>
              ) : (
                <Box
                  component="form"
                  onSubmit={onSubmit}
                  sx={{
                    margin: "0 auto",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "328px"
                  }}
                >
                  <TextField
                    value={email}
                    type="email"
                    inputMode="email"
                    name="email"
                    label="E-mail"
                    variant="filled"
                    onChange={e => setEmail(e.target.value)}
                    sx={{mb: 2}}
                  />
                  <TextField
                    value={name}
                    name="first_name"
                    label="Imię"
                    variant="filled"
                    onChange={e => setName(e.target.value)}
                    sx={{mb: 2}}
                  />
                  {
                    !!tagsToFill?.length && tagsToFill.map(tag => (
                      <TextField
                        key={tag.name}
                        value={tags[tag.name]}
                        name={tag.name}
                        label={tag.label}
                        variant="filled"
                        onChange={e => setTags(tags => ({
                          ...tags,
                          [tag.name]: e.target.value
                        }))}
                        select={!!tag.options}
                        sx={{mb: 2}}
                      >
                        {
                          tag.options?.map(option => (
                            <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                          ))
                        }
                      </TextField>
                    ))
                  }
                  <FormControlLabel
                    value={marketing}
                    onChange={(_, checked) => setMarketing(checked)}
                    control={<Checkbox/>}
                    label={
                    <Box>
                      <Typography variant="subtitle1">
                        Zgoda na wysyłkę newslettera lub informacji marketingowych przez DokDok sp. z o.o.
                        <IconButton color="primary">
                          <InfoIcon onClick={() => setInfoVisible(prevState => !prevState)} />
                        </IconButton>
                      </Typography>
                      <Typography variant="subtitle1" display={infoVisible ? "block" : "none"}>
                        Oznacza zgodę na przetwarzanie przez DokDok sp. z o.o. mojego adresu poczty elektronicznej w celu przesyłania mi informacji marketingowych dotyczących produktów i usług oferowanych przez DokDok sp. z o.o..<br />
                        W każdej chwili mogę wycofać udzieloną zgodę bez podawania przyczyny.
                      </Typography>
                    </Box>
                    }
                  />
                  <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={!isValid || isSubmitted}
                    sx={{mb: 2}}
                  >
                    Zapisz się
                  </Button>
                </Box>
              )
            }
          </Grid>
        </Grid>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
